import { useNavigate } from "react-router-dom";
import { months } from "../data/defaultValues";

const EventCard = ({ item }) => {
  const navigate = useNavigate();
  const today = new Date("2024-08-25");
  const eventDate = new Date(item.date);
  const eventEndDate = new Date(item.endDate);

  const isPastEvent = eventEndDate < today;
  const isTodayEvent = today >= eventDate && today <= eventEndDate;

  const statusText = isPastEvent
    ? "Acara sudah lewat"
    : isTodayEvent
    ? "Acara sedang berlangsung"
    : "Acara segera berlangsung";

  const statusClass = isPastEvent
    ? "bg-gray-500 text-white"
    : isTodayEvent
    ? "bg-green-600 text-white"
    : "bg-primary text-white";

  return (
    <section
      className={`p-4 my-4 md:p-8 flex w-full shadow-lg rounded-2xl relative ${
        isPastEvent ? "cursor-default bg-gray-200" : "cursor-pointer"
      }`}
      onClick={() => {
        if (!isPastEvent) {
          navigate(item.link, {
            state: item,
          });
        }
      }}
    >
      <div className="flex flex-col font-bold text-secondary items-center">
        <p>{months[eventDate.getMonth()]}</p>
        <p className="text-4xl">{eventDate.getDate()}</p>
        <p>{eventDate.getFullYear()}</p>
      </div>
      <div className="ml-4 md:ml-8">
        <p className="font-bold text-lg md:text-xl text-primary">
          {item.title}
        </p>

        <p className="text-sm md:text-base text-secondary mb-2">
          {item.detail}
        </p>
        <span
          className={`text-xs md:text-xs font-normal inline-block py-1 px-2  rounded-full ${statusClass}`}
        >
          {statusText}
        </span>
      </div>
    </section>
  );
};

export default EventCard;
