import React, { useState, useEffect } from "react";
import { db } from "./config/firebaseConfig";
import { collection, addDoc, getDocs } from "firebase/firestore";

const AddQuestion = () => {
  const [question, setQuestion] = useState("");
  const [optionA, setOptionA] = useState("");
  const [optionB, setOptionB] = useState("");
  const [optionC, setOptionC] = useState("");
  const [optionD, setOptionD] = useState("");
  const [correctAnswer, setCorrectAnswer] = useState("");
  const [weight, setWeight] = useState("");
  const [questionCount, setQuestionCount] = useState(0); // State untuk jumlah soal

  // Mengambil jumlah soal saat komponen dirender
  useEffect(() => {
    const fetchQuestionCount = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "questions"));
        setQuestionCount(querySnapshot.size);
      } catch (error) {
        console.error("Error fetching question count: ", error);
      }
    };

    fetchQuestionCount();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (
      !question ||
      !optionA ||
      !optionB ||
      !optionC ||
      !optionD ||
      !correctAnswer ||
      !weight
    ) {
      alert("Please fill out all fields and select the correct answer.");
      return;
    }

    // Convert weight to integer
    const weightInt = parseInt(weight, 10);

    if (isNaN(weightInt) || weightInt < 0) {
      alert("Please enter a valid weight (non-negative integer).");
      return;
    }

    try {
      await addDoc(collection(db, "questions"), {
        question,
        options: { A: optionA, B: optionB, C: optionC, D: optionD },
        correctAnswer,
        weight: weightInt, // Save weight as integer
      });
      alert("Question added successfully!");
      setQuestion("");
      setOptionA("");
      setOptionB("");
      setOptionC("");
      setOptionD("");
      setCorrectAnswer("");
      setWeight("");
      setQuestionCount((prevCount) => prevCount + 1); // Increment jumlah soal
    } catch (error) {
      console.error("Error adding question: ", error);
    }
  };

  return (
    <div className="max-w-lg mx-auto p-4 bg-white shadow-md rounded-lg">
      <h2 className="text-2xl font-bold text-center text-primary mb-6">
        Add New Question
      </h2>
      <p className="text-center text-gray-700 mb-4">
        Soal yang sudah ada: {questionCount}
      </p>
      <form onSubmit={handleSubmit}>
        <div className="mb-4">
          <label
            className="block text-gray-700 text-sm font-bold mb-2"
            htmlFor="question"
          >
            Question
          </label>
          <textarea
            id="question"
            type=""
            value={question}
            onChange={(e) => setQuestion(e.target.value)}
            className="w-full px-3 py-2 border rounded-lg"
          />
        </div>
        <div className="mb-4">
          <label
            className="block text-gray-700 text-sm font-bold mb-2"
            htmlFor="optionA"
          >
            Option A
          </label>
          <input
            id="optionA"
            type="text"
            value={optionA}
            onChange={(e) => setOptionA(e.target.value)}
            className="w-full px-3 py-2 border rounded-lg"
          />
        </div>
        <div className="mb-4">
          <label
            className="block text-gray-700 text-sm font-bold mb-2"
            htmlFor="optionB"
          >
            Option B
          </label>
          <input
            id="optionB"
            type="text"
            value={optionB}
            onChange={(e) => setOptionB(e.target.value)}
            className="w-full px-3 py-2 border rounded-lg"
          />
        </div>
        <div className="mb-4">
          <label
            className="block text-gray-700 text-sm font-bold mb-2"
            htmlFor="optionC"
          >
            Option C
          </label>
          <input
            id="optionC"
            type="text"
            value={optionC}
            onChange={(e) => setOptionC(e.target.value)}
            className="w-full px-3 py-2 border rounded-lg"
          />
        </div>
        <div className="mb-4">
          <label
            className="block text-gray-700 text-sm font-bold mb-2"
            htmlFor="optionD"
          >
            Option D
          </label>
          <input
            id="optionD"
            type="text"
            value={optionD}
            onChange={(e) => setOptionD(e.target.value)}
            className="w-full px-3 py-2 border rounded-lg"
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2">
            Correct Answer
          </label>
          <div className="flex justify-around">
            <label>
              <input
                type="radio"
                name="correctAnswer"
                value="A"
                checked={correctAnswer === "A"}
                onChange={(e) => setCorrectAnswer(e.target.value)}
                className="mr-2"
              />
              A
            </label>
            <label>
              <input
                type="radio"
                name="correctAnswer"
                value="B"
                checked={correctAnswer === "B"}
                onChange={(e) => setCorrectAnswer(e.target.value)}
                className="mr-2"
              />
              B
            </label>
            <label>
              <input
                type="radio"
                name="correctAnswer"
                value="C"
                checked={correctAnswer === "C"}
                onChange={(e) => setCorrectAnswer(e.target.value)}
                className="mr-2"
              />
              C
            </label>
            <label>
              <input
                type="radio"
                name="correctAnswer"
                value="D"
                checked={correctAnswer === "D"}
                onChange={(e) => setCorrectAnswer(e.target.value)}
                className="mr-2"
              />
              D
            </label>
          </div>
        </div>
        <div className="mb-4">
          <label
            className="block text-gray-700 text-sm font-bold mb-2"
            htmlFor="weight"
          >
            Weight
          </label>
          <input
            id="weight"
            type="number"
            step="1"
            min="0"
            value={weight}
            onChange={(e) => setWeight(e.target.value)}
            className="w-full px-3 py-2 border rounded-lg"
          />
        </div>
        <button
          type="submit"
          className="w-full bg-primary text-white p-2 rounded-lg hover:bg-primary-dark transition duration-300"
        >
          Add Question
        </button>
      </form>
    </div>
  );
};

export default AddQuestion;
