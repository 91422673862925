import himatekkom2 from "../assets/img/himatekkom.png";

const Profil = () => {
  return (
    <main className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-full p-10 scroll text-primary md:flex items-center ">
      <img
        src={himatekkom2}
        alt="himatekkom-teks"
        width={"100%"}
        className="opacity-10 absolute top-1/2  left-1/2 transform select-none -translate-x-1/2 -translate-y-1/2 md:hidden"
      />
      <div className="hidden pointer-events-none md:flex justify-center select-none items-center w-11/12 m-20">
        <img src={himatekkom2} alt="himatekkom-teks" className="" />
      </div>
      <div className="text-center  md:text-left md:p-12 text-primary">
        <p className="text-2xl select-none md:text-4xl font-bold">HIMATEKKOM</p>
        <p className="lg:text-lg select-none">
          Himpunan Mahasiswa Teknik Komputer
        </p>
        <p className="text-sm select-none lg:text-lg">
          Universitas Syiah Kuala
        </p>
        <p className="mt-4 text-justify select-none text-sm lg:text-lg">
          Himpunan Mahasiswa Teknik Komputer merupakan sebuah lembaga yang
          mewadahi Program Studi Teknik Komputer. Program studi ini bernaung
          pada Fakultas Teknik Universitas Syiah Kuala. HIMATEKKOM memiliki
          tujuan untuk menampung segala aspirasi serta meningkatkan kualitas
          mahasiswa di dalam lingkungan Program Studi Teknik Komputer
          Universitas Syiah Kuala.
        </p>
      </div>
    </main>
  );
};

export default Profil;
