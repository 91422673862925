import { Outlet, useLocation } from "react-router-dom";
import Header from "../components/header";

const Layout = () => {
  const location = useLocation();
  const hideHeader = location.pathname.startsWith("/olimpiade-komputer");

  return (
    <div className="overflow-x-hidden">
      {!hideHeader && <Header />}
      <div className="sm:pb-20 md:px-24 min-h-screen bg-[#f5f5f5]">
        <Outlet />
      </div>
    </div>
  );
};

export default Layout;
