import React from "react";

const Question = ({ question, onAnswerCorrect, userAnswer }) => {
  const handleAnswerChange = (selectedOption) => {
    onAnswerCorrect(question.id, selectedOption);
  };

  if (!question) {
    return <p>Loading...</p>;
  }

  const optionLabels = ["A", "B", "C", "D"];

  return (
    <div className="bg-white shadow-md rounded-lg p-4 z-10">
      <p className="font-semibold text-lg mb-2">{question.question}</p>
      {Object.entries(question.options).map(([key, option], idx) => (
        <div key={key} className="flex items-center mb-1">
          <input
            type="radio"
            id={`${question.id}-${key}`}
            name={question.id}
            value={key}
            onChange={() => handleAnswerChange(key)}
            className="mr-2"
            checked={userAnswer === key}
          />
          <label htmlFor={`${question.id}-${key}`} className="text-gray-700">
            <span className="font-semibold">{optionLabels[idx]}.</span> {option}
          </label>
        </div>
      ))}
    </div>
  );
};

export default Question;
