import React from "react";
import { useNavigate } from "react-router-dom";

const Result = () => {
  const navigate = useNavigate();
  localStorage.clear();
  const handleLoginRedirect = () => {
    navigate("/olimpiade-komputer/login");
  };

  return (
    <div className="min-h-screen flex flex-col justify-center items-center">
      <h1 className="text-4xl font-bold mb-6 text-primary">Terima Kasih!</h1>
      <p className="text-xl mb-1 text-secondary">
        Anda telah menyelesaikan soal ini.
      </p>
      <p className="text-lg mb-4 text-secondary">
        Semoga hasil Anda memuaskan.
      </p>
      <button
        onClick={handleLoginRedirect}
        className="bg-primary text-white p-2 rounded mt-4"
      >
        Login
      </button>
    </div>
  );
};

export default Result;
